import React from "react";

const Footer = () => (
  <footer className="container mx-auto  px-3 mt-16 mb-8 text-gray-800">
    <div className="flex -mx-3">
      <div className="flex-1 px-3">
        <p className="mt-5">© 2020 · Woch.io · All rights reserved</p>
      </div>
      {/*
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Important Links</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <p>Terms &amp; Conditions</p>
          </li>
          <li>
            <p>Privacy Policy</p>
          </li>
        </ul>
      </div>

      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Social Media</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <a href="https://twitter.com/woch_io">Twitter</a>
          </li>
          <li>
            <a href="https://https://www.instagram.com/woch.io/">Instagram</a>
          </li>
        </ul>
      </div>
       */}
    </div>
  </footer>
);

export default Footer;
