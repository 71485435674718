import React from "react";

function WochNotification() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      version="1.1"
      viewBox="0 0 798.82 755.15"
      xmlSpace="preserve"
    >
      <path
        fill="none"
        stroke="#69F0AE"
        strokeMiterlimit="10"
        strokeWidth="9"
        d="M23.66 448.57s-.68-2-1.78-5.72"
      />
      <path
        fill="none"
        stroke="#69F0AE"
        strokeDasharray="12.04,12.04"
        strokeMiterlimit="10"
        strokeWidth="9"
        d="M18.66 431.25c-24.31-93.6-100.1-524.98 659.66-255.68"
      />
      <path
        fill="none"
        stroke="#69F0AE"
        strokeMiterlimit="10"
        strokeWidth="9"
        d="M684.01 177.57l5.65 2"
      />
      <linearGradient
        id="SVGID_1_"
        x1="589.632"
        x2="589.632"
        y1="-32.67"
        y2="256.53"
        gradientTransform="matrix(1 0 0 -1 -198.33 685.56)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="gray" stopOpacity="0.25" />
        <stop offset="0.54" stopColor="gray" stopOpacity="0.12" />
        <stop offset="1" stopColor="gray" stopOpacity="0.1" />
      </linearGradient>
      <path
        fill="url(#SVGID_1_)"
        d="M596.6 510.26c.01-44.85-36.34-81.22-81.19-81.23-39.03-.01-72.54 27.74-79.81 66.09l-249.59-6.77 2.74 42.88s-35.59 82.12 151.46 104v83h216.24v-138a81.196 81.196 0 0040.15-69.97z"
      />
      <path
        fill="#F6B9AD"
        d="M197.32 533.03s-34.08 78.64 145.05 99.62v79.52h207.09v-210.6l-354.8-9.61 2.66 41.07z"
      />
      <linearGradient
        id="SVGID_2_"
        x1="411.331"
        x2="411.331"
        y1="462.952"
        y2="612.27"
        gradientTransform="matrix(1 0 0 -1 -198.33 685.56)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="gray" stopOpacity="0.25" />
        <stop offset="0.54" stopColor="gray" stopOpacity="0.12" />
        <stop offset="1" stopColor="gray" stopOpacity="0.1" />
      </linearGradient>
      <path
        fill="url(#SVGID_2_)"
        d="M300.82 204.86c-11.24 17.74-34.7 23.07-52.5 11.93l-111.19-73.27c-17.74-11.24-23.07-34.7-11.93-52.5 11.24-17.71 34.67-23.04 52.46-11.93l111.19 73.27c17.75 11.23 23.1 34.69 11.97 52.5z"
      />
      <path
        fill="#F6B9AD"
        d="M294.66 200.85c-10.45 16.49-32.25 21.44-48.8 11.09l-103.39-68.11c-16.49-10.45-21.44-32.25-11.09-48.8 10.45-16.49 32.25-21.44 48.8-11.09l103.37 68.12c16.49 10.44 21.45 32.24 11.11 48.79z"
      />
      <circle cx="510.14" cy="512.94" r="77.77" fill="#F6B9AD" />
      <linearGradient
        id="SVGID_3_"
        x1="545.507"
        x2="545.507"
        y1="103.6"
        y2="685.56"
        gradientTransform="matrix(1 0 0 -1 -198.33 685.56)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="gray" stopOpacity="0.25" />
        <stop offset="0.54" stopColor="gray" stopOpacity="0.12" />
        <stop offset="1" stopColor="gray" stopOpacity="0.1" />
      </linearGradient>
      <path
        fill="url(#SVGID_3_)"
        d="M198.22 0h297.92c6.77 0 12.25 5.48 12.25 12.25v557.46c0 6.77-5.48 12.25-12.25 12.25H198.22c-6.77 0-12.25-5.48-12.25-12.25V12.25c0-6.77 5.48-12.25 12.25-12.25z"
      />
      <path
        fill="#FFF"
        d="M203.97 7.87h286.42c7.53 0 13.64 6.11 13.64 13.64v538.96c0 7.53-6.11 13.64-13.64 13.64H203.97c-7.53 0-13.64-6.11-13.64-13.64V21.51c0-7.53 6.1-13.64 13.64-13.64z"
      />
      <path
        fill="#7187FF"
        d="M425.99 22.06c-1.88 12.41-12.52 21.59-25.07 21.63H292.58c-12.55-.04-23.19-9.23-25.06-21.63h-57.4c-6.58-.01-11.91 5.32-11.92 11.9v514.01c-.01 6.58 5.32 11.91 11.9 11.92h274.15c6.58.01 11.91-5.32 11.92-11.9V33.98c.01-6.58-5.32-11.91-11.9-11.92h-58.28z"
      />
      <path
        fill="#DBDBDB"
        d="M314.67 28.84h65.91c1.1 0 2 .9 2 2v.37c0 1.1-.9 2-2 2h-65.91c-1.1 0-2-.9-2-2v-.37a2 2 0 012-2z"
      />
      <circle cx="397.43" cy="30.59" r="2.62" fill="#DBDBDB" />
      <linearGradient
        id="SVGID_4_"
        x1="725.873"
        x2="725.873"
        y1="157.856"
        y2="435.104"
        gradientTransform="matrix(1 0 0 -1 -198.33 685.56)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="gray" stopOpacity="0.25" />
        <stop offset="0.54" stopColor="gray" stopOpacity="0.12" />
        <stop offset="1" stopColor="gray" stopOpacity="0.1" />
      </linearGradient>
      <path
        fill="url(#SVGID_4_)"
        d="M560.42 526.65c-22.57 5.22-38.06-9.5-43.28-32.06l-51.86-192.56c-5.16-22.65 8.93-45.21 31.55-50.51 22.65-5.16 45.21 8.93 50.51 31.55l42.32 197.5c5.22 22.6-6.68 40.86-29.24 46.08z"
      />
      <path
        fill="#F6B9AD"
        d="M499.07 261.28c19.04-4.4 38.05 7.47 42.45 26.51l47.69 206.39c4.4 19.04-7.47 38.05-26.51 42.45-19.04 4.4-38.05-7.47-42.45-26.51l-47.69-206.39c-4.4-19.05 7.47-38.05 26.51-42.45z"
      />
      <linearGradient
        id="SVGID_5_"
        x1="637.917"
        x2="637.917"
        y1="-69.59"
        y2="-0.59"
        gradientTransform="matrix(1 0 0 -1 -198.33 685.56)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="gray" stopOpacity="0.25" />
        <stop offset="0.54" stopColor="gray" stopOpacity="0.12" />
        <stop offset="1" stopColor="gray" stopOpacity="0.1" />
      </linearGradient>
      <path fill="url(#SVGID_5_)" d="M291.59 686.15H587.5899999999999V755.15H291.59z" />
      <path fill="#494949" d="M299.59 694.15H579.5899999999999V748.15H299.59z" />
      <linearGradient
        id="SVGID_6_"
        x1="396.042"
        x2="396.042"
        y1="358.136"
        y2="495.164"
        gradientTransform="matrix(1 0 0 -1 -198.33 685.56)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="gray" stopOpacity="0.25" />
        <stop offset="0.54" stopColor="gray" stopOpacity="0.12" />
        <stop offset="1" stopColor="gray" stopOpacity="0.1" />
      </linearGradient>
      <path
        fill="url(#SVGID_6_)"
        d="M276.94 309.81c-11.16 17.6-34.44 22.89-52.11 11.84l-94.5-61.53c-17.6-11.16-22.89-34.44-11.84-52.11 11.16-17.6 34.44-22.89 52.11-11.84l94.5 61.53c17.6 11.16 22.89 34.44 11.84 52.11z"
      />
      <linearGradient
        id="SVGID_7_"
        x1="386.863"
        x2="386.863"
        y1="256.592"
        y2="379.998"
        gradientTransform="matrix(1 0 0 -1 -198.33 685.56)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="gray" stopOpacity="0.25" />
        <stop offset="0.54" stopColor="gray" stopOpacity="0.12" />
        <stop offset="1" stopColor="gray" stopOpacity="0.1" />
      </linearGradient>
      <path
        fill="url(#SVGID_7_)"
        d="M257.66 411.43c-11.1 17.53-34.28 22.8-51.87 11.79l-74.58-48.25c-17.53-11.1-22.8-34.28-11.79-51.87 11.1-17.53 34.28-22.8 51.87-11.79l74.57 48.26c17.52 11.1 22.79 34.27 11.8 51.86z"
      />
      <linearGradient
        id="SVGID_8_"
        x1="382.045"
        x2="382.045"
        y1="154.29"
        y2="263.801"
        gradientTransform="matrix(1 0 0 -1 -198.33 685.56)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="gray" stopOpacity="0.25" />
        <stop offset="0.54" stopColor="gray" stopOpacity="0.12" />
        <stop offset="1" stopColor="gray" stopOpacity="0.1" />
      </linearGradient>
      <path
        fill="url(#SVGID_8_)"
        d="M238.94 513.57c-11.2 17.69-34.59 23.01-52.34 11.9l-46.19-33.7c-17.69-11.2-23.01-34.59-11.9-52.34 11.22-17.67 34.6-22.97 52.34-11.86l46.19 33.69c17.67 11.2 22.98 34.57 11.9 52.31z"
      />
      <path
        fill="#F6B9AD"
        d="M235.21 511.06c-10.45 16.49-32.25 21.44-48.8 11.09l-43.07-31.42c-16.49-10.45-21.44-32.25-11.09-48.8 10.45-16.49 32.25-21.44 48.8-11.09l43.07 31.42c16.48 10.45 21.44 32.25 11.09 48.8z"
      />
      <linearGradient
        id="SVGID_9_"
        x1="347.177"
        x2="347.177"
        y1="373.43"
        y2="434.43"
        gradientTransform="matrix(1 0 0 -1 0 758)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopOpacity="0.12" />
        <stop offset="0.55" stopOpacity="0.09" />
        <stop offset="1" stopOpacity="0.02" />
      </linearGradient>
      <path fill="url(#SVGID_9_)" d="M245.18 323.57H449.18V384.57H245.18z" />
      <path fill="#69F0AE" d="M252.18 329.57H442.18V373.57H252.18z" />
      <linearGradient
        id="SVGID_10_"
        x1="1167.314"
        x2="1167.314"
        y1="-52.917"
        y2="12.234"
        gradientTransform="scale(1 -1) rotate(-32.358 643.806 601.572)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="gray" stopOpacity="0.25" />
        <stop offset="0.54" stopColor="gray" stopOpacity="0.12" />
        <stop offset="1" stopColor="gray" stopOpacity="0.1" />
      </linearGradient>
      <path
        fill="url(#SVGID_10_)"
        d="M795.23 214.52L798.82 215.08 798.26 214.55 798.56 214.55 798.09 214.39 733 152.66 717.2 177.59 704.06 200.33 710.15 201.28 707.45 213.6z"
      />
      <path fill="#7187FF" d="M719.93 178.89L707.93 199.7 795.93 214.03 739.25 174.56z" />
      <path d="M719.93 178.89L707.93 199.7 795.93 214.03 739.25 174.56z" opacity="0.2" />
      <path fill="#7187FF" d="M734.38 156.09L795.93 214.03 719.93 178.89z" />
      <path fill="#7187FF" d="M711.17 211.93L795.69 213.54 716.79 185.52z" />
      <path
        fill="#F6B9AD"
        d="M253.56 408.82c-10.45 16.49-32.25 21.44-48.8 11.09l-70.16-45.4c-16.49-10.45-21.44-32.25-11.09-48.8 10.43-16.51 32.24-21.48 48.8-11.14l70.16 45.4c16.52 10.45 21.47 32.29 11.09 48.85z"
      />
      <path
        fill="#2A2C2B"
        d="M272.52 214.19l-2.62-4.09s-2.3-.38-.96-3.52c1.34-3.13 4.03-8.19 3.58-14.73s.22-9.45 7.39-9.22c7.16.22 12.38.15 18.35-.01 5.98-.16 11.19 0 11.19 0l-36.93 31.57z"
      />
      <path
        fill="#2A2C2B"
        d="M286.18 197.63l12.76-13.43s12.31-4.25 17.68-4.92c5.37-.67 12.53-1.57 14.1 6.94s17.23 21.71 27.08 27.53c9.85 5.82 33.35 17.01 39.84 17.9 6.49.9 11.19.6 11.19.6l-3.8 12.38-24.39 7.09-73.85-10.01-32.68-11.4 12.07-32.68z"
      />
      <path
        fill="#191C1B"
        d="M299.16 217.59l35.86-23.42c.53-.34 1.23-.25 1.65.22l3.82 4.34c.51.58.39 1.48-.26 1.91l-36.73 23.67-4.34-6.72zM325 231.61c1.23-1.3 18.75-20.85 22.67-25.22.44-.49 1.18-.56 1.7-.17l4.9 3.66c.61.45.68 1.33.17 1.88l-19.78 21.18M352.57 232.96l9.23-16.9c.33-.61 1.09-.84 1.7-.52l4.69 2.45c.6.32.85 1.05.56 1.67l-7.38 15.53-8.8-2.23zM370.48 239.26l5.67-15.97a1.27 1.27 0 011.69-.75l4.99 2.09c.61.26.92.94.71 1.57l-5.6 16.75-7.46-3.69zM392.56 241.71V230.4c0-.78.69-1.37 1.46-1.25l3.98.59c.57.09 1.02.55 1.08 1.13l.95 9.4-7.47 1.44zM290.35 194.76s22.68-5.67 27.6-5.97c3.84-.23 10.12-1.73 12.71-2.38.66-.17 1.07-.82.93-1.48l-1.16-5.78c-.14-.69-.81-1.13-1.49-.99l-30.01 6.05-6.49 4.44-2.09 6.11z"
      />
      <path
        fill="#171817"
        d="M267.38 225.83l-1.87-3.84s3.66-10.93 7.47-18.98c3.8-8.06 7.16-15.67 14.99-17.23 7.83-1.57 10.97-1.57 10.97-1.57s-7.61 8.5-2.91 23.72 12.15 17.95 25.21 22.96c13.06 5.01-12.01 8.37-12.01 8.37l-41.85-13.43z"
      />
      <path
        fill="#1D1D1D"
        d="M266.63 252.98l-4.92-2.54.45-4.48s1.64-16.11 2.01-19.55c.37-3.43 4.1-14.02 14.1-9.25s24.17 16.56 40.88 14.02c16.71-2.54 29.84-4.03 39.39.6s14.92 7.76 18.5 9.7c3.58 1.94 5.37 2.69 9.25 1.04 3.88-1.64 13.13-4.63 17.31-6.86s5.22-3.43 5.22-3.43 10 3.22 19.69 4.37c9.7 1.15 15.82 3.24 17.76 6.22 1.94 2.98 2.77 5.81 2.77 5.81l.08 1.33-4.19 5.09-53.12 8.69-84.15 3.7-41.03-14.46z"
      />
      <path d="M391.81 263.77l53.12-8.69 3.91-5.24.21-1.19s-.83-2.82-2.77-5.81c-.48-.73-1.21-1.41-2.18-2.04-9.05 3.08-22.71 7.39-40.61 12.05-34.79 9.06-47.79-10.07-54.2-5.71-6.41 4.36-28.5 2.01-58.93-8.39-10.47-3.58-19.67-3.19-27.35-1.07l-.85 8.29-.45 4.48 4.92 2.54 41.03 14.47 84.15-3.69z" />
      <path
        fill="#FFF"
        d="M263.65 268.35l-3.43-7.61.3-6.12s-.45-6.86.6-7.76l1.04-.9s-.3 3.73 2.09 4.03c2.39.3 27.01.3 36.7 2.39 9.7 2.09 21.63 4.03 32.38 1.94 10.74-2.09 24.17-3.43 34.91 1.34 10.74 4.77 13.88 6.56 18.2 5.67s10.59-6.42 15.82-5.97c5.22.45 19.84 1.64 30.29-1.04 10.44-2.69 16.11-4.63 16.11-4.63l.9 5.97-4.33 7.16-29.09 7.61-25.66 5.82-26.86-4.63-32.97-.15-42.37 3.43-24.63-6.55z"
      />
      <path
        fill="#EE3B2E"
        d="M260.51 254.62s-2.98 9.85 0 15.07c2.98 5.22 9.1 8.8 18.95 9.1s21.93.15 32.38-1.64c10.44-1.79 21.49-4.03 29.39-2.39 7.91 1.64 23.87 4.72 34.02 4.15 10.15-.57 43.87-3.25 56.55-7.28s19.4-7.91 18.65-15.82-1.64-8.36-1.79-6.12 1.85 10.74-19.21 14.06c-12.52 1.97-17.05-3.32-28.83.86-5.87 2.08-2.35 8.74-9.55 10.15-6.12 1.19-9.24-4.43-12.38-5.97-7.31-3.57-11.19-6.15-20.59-6.12-16.48.06-17.46 6.01-26.11 7.01-27.15 3.13-53.28-.48-60.28-1.64-12.36-2.04-11.2-13.42-11.2-13.42z"
      />
      <path
        fill="#171817"
        d="M343.79 201.41h-4.13a1.451 1.451 0 010-2.9h4.13a1.451 1.451 0 010 2.9zM357.69 212.03h-4.13a1.451 1.451 0 010-2.9h4.13a1.451 1.451 0 010 2.9zM372.07 220.65h-4.13a1.451 1.451 0 010-2.9h4.13a1.451 1.451 0 010 2.9zM386.84 227.31h-4.13a1.451 1.451 0 010-2.9h4.13a1.451 1.451 0 010 2.9zM402.35 232l-4.02.93c-.78.18-1.56-.31-1.74-1.09-.18-.78.31-1.56 1.09-1.74l4.02-.93c.78-.18 1.56.31 1.74 1.09.18.78-.3 1.56-1.09 1.74zM391.64 230.69l-2.92-2.92c-.57-.57-.57-1.48 0-2.05.57-.57 1.48-.57 2.05 0l2.92 2.92c.57.57.57 1.48 0 2.05-.57.57-1.49.57-2.05 0z"
      />
      <path
        fill="#171817"
        d="M376.05 223.98l-2.92-2.92c-.57-.57-.57-1.48 0-2.05.57-.57 1.48-.57 2.05 0l2.92 2.92c.57.57.57 1.48 0 2.05-.57.57-1.49.57-2.05 0zM360.5 216.33l-2.07-3.57c-.4-.69-.16-1.58.53-1.98.69-.4 1.58-.16 1.98.53l2.07 3.57c.4.69.16 1.58-.53 1.98-.69.4-1.57.16-1.98-.53zM346.57 206.03l-2.07-3.57c-.4-.69-.16-1.58.53-1.98.69-.4 1.58-.16 1.98.53l2.07 3.57c.4.69.16 1.58-.53 1.98-.69.4-1.58.17-1.98-.53zM333.77 194.1l-.19-4.12a1.453 1.453 0 012.9-.14l.19 4.12c.04.8-.58 1.48-1.38 1.52a1.44 1.44 0 01-1.52-1.38z"
      />
      <path
        fill="#69F0AE"
        d="M434.99 152.71L423.12 143.61 391.68 184.62 374.21 171.23 365.11 183.09 388.5 201.01 388.5 201.01 394.51 205.51z"
      />
      <path
        fill="#F6B9AD"
        d="M271.92 306.57c-10.45 16.49-32.25 21.44-48.8 11.09l-88.51-57.63c-16.49-10.45-21.44-32.25-11.09-48.8 10.45-16.49 32.25-21.44 48.8-11.09l88.51 57.63c16.48 10.45 21.44 32.25 11.09 48.8z"
      />
    </svg>
  );
}

export default WochNotification;
