import React from "react";

import WochLogo from "../../svg/WochLogo";

const Header = () => (
  <header className="sticky top-0 bg-white shadow">
    <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
      <div className="flex items-center text-2xl">
        <div className="w-32 mr-0">
          <WochLogo />
        </div>
      </div>
      <div className="flex mt-4 sm:mt-0">
        {/*  <AnchorLink className="px-4" href="#features">
          Features
        </AnchorLink>
        <AnchorLink className="px-4" href="#FAQ">
          FAQ
        </AnchorLink>
        <AnchorLink className="px-4" href="#stats">
          Stats
</AnchorLink> */}
      </div>
      <div className="hidden md:block">
        {/* <Button className="text-sm">Get Early Access</Button> */}
      </div>
    </div>
  </header>
);

export default Header;
