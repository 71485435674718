import React from "react";

function WochLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      version="1"
      viewBox="0 0 159.48 47.42"
      xmlSpace="preserve"
    >
      <circle cx="68.53" cy="29.14" r="18.11" fill="#536DFE" />
      <circle cx="68.53" cy="29.14" r="10.12" fill="#FFF" />
      <circle cx="68.53" cy="29.14" r="8.56" fill="#2FDDD0" />
      <circle cx="68.53" cy="29.14" r="3.63" fill="#636978" />
      <circle cx="63.47" cy="26.2" r="1.98" fill="#FFF" />
      <circle cx="67.03" cy="28.51" r="1.08" fill="#FFF" />
      <g>
        <path
          fill="#536DFE"
          d="M0 12.95h12.68v18.2c0 1.42.15 2.41.44 2.97.29.55.82.83 1.59.83 1.42 0 2.14-1.27 2.14-3.8v-18.2h12.68v18.2c0 1.42.15 2.41.46 2.97.31.55.84.83 1.61.83 1.4 0 2.1-1.27 2.1-3.8v-18.2h12.68v20.81c0 3.8-1.28 7.02-3.83 9.68s-5.65 3.98-9.29 3.98c-3.75 0-7.12-1.58-10.1-4.75-3.3 3.16-6.62 4.75-9.96 4.75-4.23 0-7.61-1.54-10.17-4.61C1.01 40.39 0 36.9 0 32.33V12.95z"
        />
      </g>
      <g fill="#536DFE">
        <path d="M113.34 30.71l12.57-.47c-.36 4.88-2.18 8.89-5.46 12.03-3.28 3.14-7.26 4.71-11.96 4.71-4.97 0-9.17-1.73-12.59-5.19-3.42-3.46-5.13-7.69-5.13-12.71 0-4.77 1.73-8.85 5.19-12.24 3.46-3.39 7.6-5.08 12.44-5.08 4.23 0 7.95 1.33 11.18 4 3.23 2.67 5.24 6.07 6.03 10.2l-12.85.47c-1.11-1.45-2.51-2.17-4.2-2.17-1.45 0-2.63.47-3.56 1.42-.93.95-1.39 2.15-1.39 3.59 0 1.47.49 2.7 1.47 3.69.98.99 2.21 1.49 3.68 1.49 2.15.02 3.68-1.23 4.58-3.74zM142.43 0v14.13c2.15-1.56 4.29-2.34 6.44-2.34 3.25 0 5.84 1.18 7.74 3.52 1.91 2.35 2.86 5.52 2.86 9.52v21.42H146.8V28.4c0-1.45-.15-2.42-.46-2.91-.31-.5-.9-.75-1.78-.75-1.42 0-2.14 1.05-2.14 3.15v18.37h-12.68V0h12.69z" />
      </g>
    </svg>
  );
}

export default WochLogo;
