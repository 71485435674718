import React from "react";

function WochPreview() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      version="1.1"
      viewBox="0 0 1118.03 765.72"
      xmlSpace="preserve"
    >
      <path
        fill="#6C63FF"
        d="M651.94 138.13c-71.16-2.28-138.94-23.52-203.65-46.75S319.46 42.38 250 30.1c-44.68-7.92-95.76-9-131.76 13.1-34.64 21.3-45.84 58.08-51.86 92.21-4.53 25.67-7.19 52.69 5.21 76.73 8.62 16.68 23.91 30.71 34.48 46.69 36.81 55.62 10.79 124.2-29.09 178.5-18.71 25.44-40.4 49.78-54.84 76.89s-21.1 58.19-8.48 85.85c12.52 27.42 42.35 48 74.66 62.5 65.63 29.42 142.97 37.84 218.43 42.61 166.89 10.59 334.68 5.98 502.11 1.41 61.94-1.7 124.11-3.41 185.1-12.27 33.84-4.92 68.78-12.72 93.33-31.56 31.2-23.91 38.92-64.41 18.02-94.39-35.05-50.3-131.97-62.8-156.47-116.77-13.51-29.72.39-62.83 19.98-90.37 42.04-59.11 112.51-111 116.22-178.54 2.55-46.41-31.32-92.89-83.71-114.85-54.9-23-131.06-20.12-171.56 18-41.7 39.21-114.99 54.31-177.83 52.29z"
        opacity="0.1"
      />
      <g opacity="0.5">
        <linearGradient
          id="SVGID_1_"
          x1="726.37"
          x2="966.427"
          y1="46.84"
          y2="613.697"
          gradientTransform="matrix(1 -.00244 -.00244 -1 -279.47 705.884)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="gray" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="gray" stopOpacity="0.12" />
          <stop offset="1" stopColor="gray" stopOpacity="0.1" />
        </linearGradient>
        <path
          fill="url(#SVGID_1_)"
          d="M707.33 100.02l-283.76.69c-6.44.01-11.65 5.24-11.64 11.68l1.29 523.05c.02 6.44 5.25 11.65 11.69 11.63l283.76-.69c6.44-.02 11.65-5.25 11.63-11.69L719 111.64c-.03-6.42-5.24-11.62-11.67-11.62z"
        />
        <path
          fill="#FFF"
          d="M426.76 100.25l277.42-.68c5.9-.01 10.7 4.76 10.71 10.66l1.27 517.95c.01 5.9-4.76 10.7-10.66 10.71l-277.42.68c-5.9.01-10.7-4.76-10.71-10.66l-1.27-517.95c-.01-5.9 4.76-10.69 10.66-10.71z"
        />
        <path
          fill="#BBB"
          d="M707.73 249.02l-.3-124.55c-.02-6.27-5.11-11.33-11.37-11.32l-55.5.13c-1.75 11.82-11.87 20.6-23.82 20.66l-103.2.25c-11.95-.01-22.11-8.73-23.92-20.55l-54.67.13c-6.27.02-11.33 5.11-11.32 11.37l.31 125.6.89 363.98c.03 6.27 5.13 11.32 11.4 11.3l261.11-.63c6.24-.03 11.29-5.11 11.29-11.35l-.9-365.02z"
        />
        <path
          fill="#DBDBDB"
          d="M531.07 120.4l63.44-.95c.87-.01 1.58.68 1.59 1.54l.02 1.03c.01.87-.68 1.58-1.54 1.59l-63.44.95c-.87.01-1.58-.68-1.59-1.54l-.02-1.03c-.02-.87.67-1.58 1.54-1.59z"
        />
        <circle cx="610.25" cy="120.87" r="2.5" fill="#DBDBDB" />
        <path fill="#E0E0E0" d="M542.04 180.67H681.15V191.76H542.04z" />
        <path fill="#E0E0E0" d="M542.04 202.85H681.15V213.94H542.04z" />
        <ellipse cx="473.1" cy="192.73" fill="#F5F5F5" rx="26.27" ry="26.4" />
        <path
          fill="#6C63FF"
          d="M484.42 211.21h-22.79a6.811 6.811 0 00-5.55 2.95 34.156 34.156 0 0033.68.19 6.73 6.73 0 00-5.34-3.14z"
        />
        <path
          fill="#333"
          d="M461.82 193.41s-3.34 9.71-5.16 8.8c0 0 17.58 15.18 33.1 0a46.19 46.19 0 00-6.68-8.8h-21.26z"
        />
        <circle cx="473.09" cy="192.5" r="13.06" fill="#333" />
        <path
          d="M468.5 206.68h9.11v3.54c0 2.31-1.87 4.18-4.18 4.18h-.78c-2.31 0-4.18-1.87-4.18-4.18v-3.54h.03z"
          opacity="0.1"
        />
        <path
          fill="#FDA57D"
          d="M469.46 206.47h7.19c.53 0 .96.43.96.96v2.6c0 2.31-1.87 4.18-4.18 4.18h-.78a4.178 4.178 0 01-4.15-4.18v-2.58c-.01-.53.41-.97.94-.99.01.01.01.01.02.01z"
        />
        <path d="M468.52 207.78c2.94 1.09 6.17 1.09 9.11 0v-1.06h-9.11v1.06z" opacity="0.1" />
        <circle cx="473.09" cy="195.33" r="13.06" fill="#FDA57D" />
        <path
          fill="#333"
          d="M460.61 190.38h24.89s-2.12-10.05-11.54-9.4c-9.41.65-13.35 9.4-13.35 9.4z"
        />
        <ellipse cx="460.3" cy="194.78" fill="#FDA57D" rx="1.21" ry="2.28" />
        <ellipse cx="485.81" cy="194.78" fill="#FDA57D" rx="1.21" ry="2.28" />
        <path d="M460.61 190.72h24.89s-2.12-10.05-11.54-9.4-13.35 9.4-13.35 9.4z" opacity="0.1" />
        <path fill="#E0E0E0" d="M542.04 265.16H681.15V276.25H542.04z" />
        <path fill="#E0E0E0" d="M542.04 287.34H681.15V298.42999999999995H542.04z" />
        <ellipse cx="473.1" cy="277.22" fill="#E0E0E0" rx="26.27" ry="26.4" />
        <path fill="#E0E0E0" d="M542.04 349.65H681.15V360.73999999999995H542.04z" />
        <path fill="#E0E0E0" d="M542.04 371.83H681.15V382.91999999999996H542.04z" />
        <ellipse cx="473.1" cy="361.71" fill="#E0E0E0" rx="26.27" ry="26.4" />
        <path fill="#E0E0E0" d="M542.04 434.14H681.15V445.22999999999996H542.04z" />
        <path fill="#E0E0E0" d="M542.04 456.32H681.15V467.40999999999997H542.04z" />
        <ellipse cx="473.1" cy="446.2" fill="#E0E0E0" rx="26.27" ry="26.4" />
        <path fill="#E0E0E0" d="M542.04 518.63H681.15V529.72H542.04z" />
        <path fill="#E0E0E0" d="M542.04 540.81H681.15V551.9H542.04z" />
        <ellipse cx="473.1" cy="530.69" fill="#E0E0E0" rx="26.27" ry="26.4" />
      </g>
      <linearGradient
        id="SVGID_2_"
        x1="909.304"
        x2="1148.442"
        y1="-16.959"
        y2="547.74"
        gradientTransform="matrix(1 -.00244 -.00244 -1 -279.47 705.884)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="gray" stopOpacity="0.25" />
        <stop offset="0.54" stopColor="gray" stopOpacity="0.12" />
        <stop offset="1" stopColor="gray" stopOpacity="0.1" />
      </linearGradient>
      <path
        fill="url(#SVGID_2_)"
        d="M889.96 165.73l-283.77.69c-6.44.02-11.65 5.25-11.63 11.69l1.29 520.48c.02 6.44 5.25 11.65 11.69 11.63l283.77-.69c6.43-.03 11.62-5.25 11.62-11.67l-1.29-520.48c0-6.44-5.23-11.66-11.68-11.65.01 0 .01 0 0 0z"
      />
      <path
        fill="#FFF"
        d="M609.39 163.4l277.42-.68c5.9-.01 10.7 4.76 10.71 10.66l1.27 517.95c.01 5.9-4.76 10.7-10.66 10.71l-277.42.68c-5.9.01-10.7-4.76-10.71-10.66l-1.27-517.95c-.01-5.9 4.76-10.69 10.66-10.71z"
      />
      <path
        fill="#FAFAFA"
        d="M890.36 313.03l-.3-125.43c-.02-6.27-5.11-11.33-11.37-11.32l-55.51.13c-1.75 11.82-11.87 20.6-23.82 20.66l-103.19.25c-11.95-.01-22.11-8.73-23.92-20.55l-54.67.13c-6.27.02-11.33 5.11-11.32 11.37l.31 125.6"
      />
      <path
        fill="#FAFAFA"
        d="M606.56 313.89l.89 363.98c.02 6.27 5.11 11.33 11.37 11.32l261.11-.63c6.27-.02 11.33-5.11 11.32-11.37l-.89-365.03"
      />
      <path fill="#E0E0E0" d="M631.66 228.46H687.13V283.93H631.66z" />
      <g opacity="0.5">
        <linearGradient
          id="SVGID_3_"
          x1="748.907"
          x2="748.907"
          y1="313.379"
          y2="437.982"
          gradientTransform="matrix(1 0 0 -1 0 768)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="gray" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="gray" stopOpacity="0.12" />
          <stop offset="1" stopColor="gray" stopOpacity="0.1" />
        </linearGradient>
        <path fill="url(#SVGID_3_)" d="M565.76 330.02H932.06V454.62H565.76z" />
      </g>
      <path fill="#FFF" d="M570.12 335.14H927.71V450.34999999999997H570.12z" />
      <path fill="#6C63FF" d="M712.73 377.81H851.84V388.9H712.73z" />
      <path fill="#6C63FF" d="M712.73 400H851.84V411.09H712.73z" />
      <path fill="#E0E0E0" d="M712.73 500.7H851.84V511.78999999999996H712.73z" />
      <path fill="#EEE" d="M712.73 522.89H851.84V533.98H712.73z" />
      <path fill="#6C63FF" d="M751.14 272.84H813.4399999999999V292.46999999999997H751.14z" />
      <ellipse cx="643.79" cy="389.87" fill="#F5F5F5" rx="26.27" ry="26.4" />
      <path
        fill="#6C63FF"
        d="M655.11 408.35h-22.79a6.811 6.811 0 00-5.55 2.95 34.156 34.156 0 0033.68.19 6.761 6.761 0 00-5.34-3.14z"
      />
      <path
        fill="#333"
        d="M632.51 390.55s-3.34 9.71-5.16 8.8c0 0 17.58 15.18 33.1 0a46.19 46.19 0 00-6.68-8.8h-21.26z"
      />
      <circle cx="643.77" cy="389.64" r="13.06" fill="#333" />
      <path
        d="M639.18 403.83h9.11v3.54c0 2.31-1.87 4.18-4.18 4.18h-.78c-2.31 0-4.18-1.87-4.18-4.18v-3.54h.03z"
        opacity="0.1"
      />
      <path
        fill="#FDA57D"
        d="M640.15 403.61h7.19c.53 0 .96.43.96.96v2.58a4.18 4.18 0 01-4.18 4.19h-.8c-2.31 0-4.18-1.87-4.18-4.18v-2.58c0-.53.43-.96.96-.96l.05-.01z"
      />
      <path d="M639.2 404.9c2.94 1.09 6.17 1.09 9.11 0v-1.12h-9.11v1.12z" opacity="0.1" />
      <circle cx="643.77" cy="392.47" r="13.06" fill="#FDA57D" />
      <path
        fill="#333"
        d="M631.26 387.52h24.89s-2.12-10.05-11.54-9.4c-9.41.65-13.35 9.4-13.35 9.4z"
      />
      <ellipse cx="630.99" cy="391.92" fill="#FDA57D" rx="1.21" ry="2.28" />
      <ellipse cx="656.49" cy="391.92" fill="#FDA57D" rx="1.21" ry="2.28" />
      <path
        d="M631.26 387.86h24.89s-2.12-10.05-11.54-9.4c-9.41.65-13.35 9.4-13.35 9.4z"
        opacity="0.1"
      />
      <ellipse cx="660.86" cy="513.62" fill="#E0E0E0" rx="26.27" ry="26.4" />
      <ellipse cx="660.86" cy="615.18" fill="#E0E0E0" rx="26.27" ry="26.4" />
      <path fill="#E0E0E0" d="M712.73 598.85H851.84V609.94H712.73z" />
      <path fill="#EEE" d="M712.73 621.03H851.84V632.12H712.73z" />
      <path fill="#E0E0E0" d="M712.73 228.46H851.84V239.55H712.73z" />
      <path fill="#E0E0E0" d="M712.73 250.65H851.84V261.74H712.73z" />
      <path
        fill="#DBDBDB"
        d="M713.69 183.56l63.44-.95c.87-.01 1.58.68 1.59 1.54l.02 1.03c.01.87-.68 1.58-1.54 1.59l-63.44.95c-.87.01-1.58-.68-1.59-1.54l-.02-1.03c-.01-.87.68-1.58 1.54-1.59z"
      />
      <circle cx="792.88" cy="184.04" r="2.5" fill="#DBDBDB" />
      <circle cx="643.9" cy="388.9" r="28.1" fill="#536DFE" />
      <circle cx="643.9" cy="388.9" r="15.7" fill="#FFF" />
      <circle cx="643.9" cy="388.9" r="13.28" fill="#2FDDD0" />
      <circle cx="643.9" cy="388.9" r="5.64" fill="#636978" />
      <circle cx="636.05" cy="384.33" r="3.08" fill="#FFF" />
      <circle cx="641.57" cy="387.93" r="1.67" fill="#FFF" />
      <g>
        <circle cx="472.93" cy="192.5" r="28.1" fill="#536DFE" />
        <circle cx="472.93" cy="192.5" r="15.7" fill="#FFF" />
        <circle cx="472.93" cy="192.5" r="13.28" fill="#2FDDD0" />
        <circle cx="472.93" cy="192.5" r="5.64" fill="#636978" />
        <circle cx="465.09" cy="187.93" r="3.08" fill="#FFF" />
        <circle cx="470.6" cy="191.52" r="1.67" fill="#FFF" />
      </g>
      <g>
        <path
          fill="#7289DA"
          d="M389.56 219.85c-18.08-59.77-59.75-100.14-59.75-100.14-32.86-11.94-58.36-3.61-58.36-3.61l-1.72 3.79c34.74-.08 53.8 9.49 53.8 9.49-22.66-4.6-43.36-4.06-61.79-.74A165.522 165.522 0 00224 140.47c-1.12.34-2 .81-3.07 1.34-6.33 2.73-21.58 9.8-38.96 24.67-6.03 5.1-9.47 8.59-9.47 8.59s10.89-19.45 41.72-39.21l-2.55-1.68s-25.65 7.15-46.57 35.36c0 0-12.3 56.69 5.79 116.47 0 0 23.9 22.22 65.71 11 0 0 4.44-10.34 7.91-19.16-25.95.08-39.42-12-39.42-12s2.27.74 6.21 1.6c.19-.06.43.07.86.15.68.2 1.29.22 1.97.43 5.48 1.21 10.79 1.85 15.61 2.23 8.65.86 18.68.89 29.98-.28 14.85-1.63 31.62-5.68 48.59-14.5 8.3-4.35 16.55-9.51 24.73-16.28 5.82-4.42 12.05-10.19 18.2-17.57 0 0-4.78 18.01-27.23 32.37 7.72 5.23 16.9 11.25 16.9 11.25 41.01-13.84 48.59-45.59 48.65-45.4zm-149.55 26.23c-10.49 3.17-21.89-3.4-25.35-14.83-3.46-11.43 2.2-23.16 12.88-26.39 10.68-3.23 22.08 3.34 25.35 14.83 3.45 11.42-2.2 23.16-12.88 26.39zm68.39-20.7c-10.49 3.17-21.89-3.4-25.35-14.83-3.46-11.43 2.2-23.16 12.88-26.39 10.49-3.17 21.89 3.4 25.35 14.83 3.45 11.43-2.2 23.16-12.88 26.39z"
        />
      </g>
    </svg>
  );
}

export default WochPreview;
