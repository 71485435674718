import React from "react";

function WochBot() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      version="1.1"
      viewBox="0 0 1124.68 770.77"
      xmlSpace="preserve"
    >
      <path
        fill="#7187FF"
        d="M1060.81 449.49c-37.66 47.88-23.03 117.84.9 173.86 8.01 18.76 17.08 38.6 13.69 58.72-4.13 24.6-26.09 42.58-49.14 52.12-42.02 17.38-92.3 13.24-130.91-10.78-33.36-20.76-57.26-54.51-91.19-74.32-56.79-33.15-129.07-20.63-190.74 2.2-43.63 16.16-91.32 37.17-134.67 20.28-30.5-11.88-51.59-40.8-63.08-71.45-5.55-14.8-9.57-30.82-20.16-42.55-6.3-6.97-14.51-11.92-22.93-16.1-76.87-38.11-173.96-17.7-248.63-59.98-50.43-28.55-82.71-82.41-97-138.56s-12.63-115.04-9-172.88c2.58-41.1 7.21-84.98 33.47-116.7C79.21 19.8 126.89 7.32 169.98 13.7s82.16 29.11 116.52 55.88c42.95 33.46 82.78 75.13 135.51 88.69 35.91 9.23 73.69 4.18 110.72 2.43 61.92-2.92 123.86 3.58 185.43 10.7 58.95 6.81 118.32 14.31 174.48 33.49 39.74 13.57 71.6 38.2 109.13 55.41 24.46 11.22 51.13 14.31 74.29 28.6 28.53 17.6 54.68 48.21 47.37 84.03-6.94 34.07-42.63 51.15-62.62 76.56z"
        opacity="0.1"
      />
      <path
        fill="#7187FF"
        d="M439.1 200.49h223.12c12.52 0 22.67 10.15 22.67 22.67v79.17c0 12.52-10.15 22.67-22.67 22.67H439.1c-12.52 0-22.67-10.15-22.67-22.67v-79.17c0-12.52 10.15-22.67 22.67-22.67z"
      />
      <path d="M437.83 305.88H663.49V308.21H437.83z" opacity="0.1" />
      <path d="M437.83 219.95H663.49V305.53999999999996H437.83z" opacity="0.3" />
      <circle cx="490.36" cy="264.53" r="31.12" opacity="0.1" />
      <circle cx="610.96" cy="264.53" r="31.12" opacity="0.1" />
      <path fill="#7187FF" d="M527.31 324.99H573.9899999999999V352.23H527.31z" />
      <path fill="#7187FF" d="M453.39 344.45H647.92V500.07H453.39z" />
      <path fill="#7187FF" d="M449.5 531.19H651.81V550.6400000000001H449.5z" />
      <path
        fill="#7187FF"
        d="M482.57 597.34L482.57 620.68 482.57 655.69 482.57 675.15 482.57 686.82 517.59 686.82 517.59 675.15 517.59 655.69 517.59 620.68 517.59 597.34 517.59 570.1 482.57 570.1z"
      />
      <path
        fill="#7187FF"
        d="M587.62 570.1L587.62 597.34 587.62 620.68 587.62 655.69 587.62 675.15 587.62 686.82 622.64 686.82 622.64 675.15 622.64 655.69 622.64 620.68 622.64 597.34 622.64 570.1z"
      />
      <path
        fill="#7187FF"
        d="M426.9 379.46v35.98c-9.61 1.68-18.57 6-25.87 12.47a61.267 61.267 0 00-15.4 22.58 114.614 114.614 0 00-7.58 34.47c-1.1 15.44-.38 30.96 2.15 46.24h-38.9a265.534 265.534 0 011.02-55.8c1.84-15.3 6.05-30.23 12.49-44.23 11.27-23.64 27.3-36.47 41.39-43.43a76.3 76.3 0 0130.7-8.28z"
      />
      <path d="M482.57 570.1H517.59V597.33H482.57z" opacity="0.3" />
      <path d="M482.57 620.68H517.59V655.6999999999999H482.57z" opacity="0.3" />
      <path d="M482.57 675.15H517.59V686.8199999999999H482.57z" opacity="0.3" />
      <path d="M587.62 570.1H622.64V597.33H587.62z" opacity="0.3" />
      <path d="M587.62 620.68H622.64V655.6999999999999H587.62z" opacity="0.3" />
      <path d="M587.62 675.15H622.64V686.8199999999999H587.62z" opacity="0.3" />
      <path
        d="M426.9 379.46v35.98c-9.61 1.68-18.57 6-25.87 12.47l-4.84-40.18a76.54 76.54 0 0130.71-8.27zM385.63 450.49a114.614 114.614 0 00-7.58 34.47l-35.73-9.57c1.84-15.3 6.05-30.23 12.49-44.23l30.82 19.33z"
        opacity="0.3"
      />
      <path
        fill="#7187FF"
        d="M674.42 379.46v35.98c9.61 1.68 18.57 6 25.87 12.47a61.267 61.267 0 0115.4 22.58c4.29 11.03 6.85 22.66 7.58 34.47 1.1 15.44.38 30.96-2.15 46.24h38.91c1.62-18.59 1.28-37.29-1.02-55.8-1.84-15.3-6.05-30.23-12.49-44.23-11.27-23.64-27.3-36.47-41.39-43.43a76.39 76.39 0 00-30.71-8.28z"
      />
      <path
        d="M674.42 379.46v35.98c9.61 1.68 18.57 6 25.87 12.47l4.84-40.18a76.54 76.54 0 00-30.71-8.27zM715.68 450.49c4.29 11.03 6.85 22.66 7.58 34.47l35.73-9.57c-1.84-15.3-6.05-30.23-12.49-44.23l-30.82 19.33z"
        opacity="0.3"
      />
      <path d="M527.31 324.99H573.9899999999999V328.21000000000004H527.31z" opacity="0.1" />
      <path
        d="M651.38 372.17v65.16a13.574 13.574 0 01-3.62-9.24V381.4c0-3.42 1.29-6.72 3.62-9.23zM454.09 376.47v48.77c-.23 3.08-1.51 6-3.63 8.25v-65.28c2.11 2.26 3.39 5.18 3.63 8.26z"
        opacity="0.1"
      />
      <path d="M463.12 500.07H638.19V502.37H463.12z" opacity="0.1" />
      <path d="M459.23 550.65H544.82V552.4399999999999H459.23z" opacity="0.1" />
      <path d="M552.61 550.65H638.2V552.4399999999999H552.61z" opacity="0.1" />
      <path fill="#7187FF" d="M468.78 85.28H482.17999999999995V200.5H468.78z" />
      <path fill="#7187FF" d="M622.4 85.28H635.8V200.5H622.4z" />
      <circle cx="475.03" cy="86.17" r="15.18" opacity="0.1" />
      <circle cx="475.03" cy="85.28" r="15.18" fill="#7187FF" />
      <circle cx="629.54" cy="86.17" r="15.18" opacity="0.1" />
      <circle cx="629.54" cy="85.28" r="15.18" fill="#7187FF" />
      <path
        fill="#FFF"
        d="M943.77 476a5.866 5.866 0 01-2.02-2.9c-.18.9.38 2.08 2.02 3.6 9.04 8.34 61.87 9.73 61.87 9.73s0-.25-.01-.7c-.33-.01-52.85-1.42-61.86-9.73z"
        opacity="0.5"
      />
      <circle cx="339.48" cy="544.82" r="13.77" fill="#FFF" />
    </svg>
  );
}

export default WochBot;
