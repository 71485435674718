import addToMailchimp from "gatsby-plugin-mailchimp";
import React from "react";
import ReactModal from "react-modal";

import Button from "../components/Button";
import Card from "../components/Card";
import LabelText from "../components/LabelText";
import Layout from "../components/layout/Layout";
import SplitSection from "../components/SplitSection";
import WochBot from "../svg/WochBot";
import WochNotification from "../svg/WochNotification";
import WochPreview from "../svg/WochPreview";
import WochWin from "../svg/WochWin";

export default () => {
  const [email, setEmail] = React.useState('');
  const [emailModalOpen, setEmailModalOpen] = React.useState(false);
  const [mailchimpResult, setMailchimpResult] = React.useState('');

  const handleInputChange = event => {
    const { target } = event;
    const { value } = target;
    setEmail(value);
  };

  const isMobile = typeof window !== `undefined` ? window.innerWidth < 800 : null;

  const getMailChimpResultMessage = result => {
    return result.includes(email)
      ? "You've already signed up! We'll contact you when we release."
      : "Thanks for signing up. We'll hit you up soon!";
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (email === undefined || email === '') {
      setMailchimpResult('Please provide an email.');
    } else {
      addToMailchimp(email).then(data => {
        setMailchimpResult(getMailChimpResultMessage(data.msg));
      });
    }
  };

  return (
    <Layout>
      <section className="pt-20 md:pt-40">
        <div className="container mx-auto px-8 lg:flex">
          <div className="text-center lg:text-left lg:w-1/2">
            <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
              Be the first to know.
            </h1>
            <p className="text-xl lg:text-2xl mt-6 font-light">
              Woch continuously monitors top retail sites for real-time stock updates and provides
              an exclusive community to notify you when products release or restock.{' '}
              <b>No more missing out on restocks or hearing about a shock drop too late!</b>
            </p>
            <p className="mt-8 md:mt-12">
              <Button onClick={() => setEmailModalOpen(true)} size="lg">
                Get Early Access
              </Button>
            </p>
            <p className="mt-4 text-gray-600" />
          </div>
          <div className="mt-16 lg:w-1/2 sm:mt-0">
            <WochNotification />
          </div>
        </div>
      </section>
      {/*
        TODO: Add Supported Sites

        <section id="stores" className="py-20 lg:pt-32">
        <div className="container mx-auto text-center">
          <LabelText className="text-gray-600">SOME SITES We Monitor</LabelText>
          <div className="flex flex-col sm:flex-row mt-8 lg:px-24 justify-between items-center mt-4">
            <div className="w-full sm:w-1/5 p-4">
              <ShopifyLogo />
            </div>
            <div className="w-full sm:w-1/5 p-4">
              <SupremeLogo />
            </div>
            <div className="w-full sm:w-1/5 p-4">
              <FootLockerLogo />
            </div>
            <div className="w-full sm:w-1/5 p-4">
              <SNKRSLogo />
            </div>
            <div className="w-full sm:w-1/5 p-4">
              <PalaceLogo />
            </div>
          </div>
        </div>
  </section>   */}
      <section id="features" className="py-20 ">
        <div className="container mx-auto text-center">
          <LabelText className="text-gray-600">Features</LabelText>
          <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
            <div className="flex-1 px-3">
              <Card className="mb-8">
                <p className="font-semibold text-xl">Real Time Stock Updates</p>
                <p className="mt-4">
                  Woch is contanstly monitoring sites. Get notified instantly when sneakers and all
                  things hype are released or restocked.
                </p>
              </Card>
            </div>
            <div className="flex-1 px-3">
              <Card className="mb-8">
                <p className="font-semibold text-xl">Live Market Comparisons</p>
                <p className="mt-4">
                  We provide comparisons of retail prices to current resell prices for items to help
                  you make quick and smart financial moves.
                </p>
              </Card>
            </div>
            <div className="flex-1 px-3">
              <Card className="mb-8">
                <p className="font-semibold text-xl">Direct Links</p>
                <p className="mt-4">
                  Each notification contains a direct link to the product, getting you there faster
                  and increasing your odds of copping.
                </p>
              </Card>
            </div>
          </div>
        </div>
      </section>
      <section id="FAQ" className="">
        <div className="container mx-auto text-center mb-12">
          <LabelText className="text-gray-600">FAQ</LabelText>
        </div>
        <SplitSection
          primarySlot={
            <div className="">
              <h3 className="text-3xl font-semibold leading-tight">How does Woch work?</h3>
              <p className="mt-8 text-xl font-light leading-relaxed">
                Woch has bots setup to monitor online retailers for stock updates of sneakers and
                hype products. When Woch sees that a product has released or restocked, we send a
                notification to our private Discord server with a direct link to the product for
                fast checkout and a live market value to help you decide quickly if you want to
                purchase and minimize your financial risk.
              </p>
            </div>
          }
          secondarySlot={<WochPreview />}
        />

        <SplitSection
          reverseOrder
          primarySlot={
            <div className="">
              <h3 className="text-3xl font-semibold leading-tight">Why are memberships limited?</h3>
              <p className="mt-8 text-xl font-light leading-relaxed">
                Woch limits memberships in an effort to give our members more W's per member. We
                care about providing a service that benefits our customers. We would rather provide
                a great service to few than a decent service to many.
              </p>
            </div>
          }
          secondarySlot={<WochWin />}
        />
        <SplitSection
          id="services"
          primarySlot={
            <div className="">
              <h3 className="text-3xl font-semibold leading-tight">Do I need a bot to use Woch?</h3>
              <p className="mt-8 text-xl font-light leading-relaxed">
                No bot necessary! Bot usage is what makes Woch so powerful, but it might not be for
                the reason you first think. With sites cracking down on bot purchases, retailers are
                getting better and better at filtering those bot purchases out. This leads to the
                store restocking all of those purchases made by the bots and gives purchasers
                another opportunity to grab the product for retail price! Woch lets you know as soon
                as restocks occur and provides you a direct link to checkout.
              </p>
            </div>
          }
          secondarySlot={<WochBot />}
        />
      </section>
      <section className="container mx-auto mt-16 lg:mt-0 py-24 bg-gray-200 rounded-lg text-center">
        <h3 className="text-5xl font-semibold ml-4 mr-4 ">Ready to get ahead in the hype game?</h3>
        <p className="mt-8 text-xl font-light">
          Early Access members will be locked in at the Early Access price for the duration of their
          membership.
        </p>
        <p className="mt-8">
          <Button type="button" onClick={() => setEmailModalOpen(true)} size="xl">
            Get Early Access
          </Button>
        </p>
        <p className="mt-8 text-xl font-light" />
      </section>
      <ReactModal
        style={{
          content: {
            background: '#536DFE',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            outline: '#536DFE',
            padding: '20px',
            height: !isMobile ? '40%' : '',
            width: !isMobile ? '40%' : '',
            position: !isMobile ? 'fixed' : '',
            top: !isMobile ? '50%' : '',
            left: !isMobile ? '50%' : '',
            transform: !isMobile ? 'translate(-50%, -50%)' : ''
          }
        }}
        isOpen={emailModalOpen}
        onRequestClose={() => setEmailModalOpen(false)}
      >
        <div className="flex flex-col items-center mt-6">
          <h3 className="text-2xl font-semibold leading-tight mt-4 text-white">
            Wow, you must be in the know because you're early!{' '}
            <span role="img" aria-label="sunglasses emoji">
              😎
            </span>
          </h3>
          <p className="mt-4 text-white">
            Sign up with your email below and you'll be sent an invitation as soon as Woch is
            available.
          </p>
          <p className="mt-4 text-white">{mailchimpResult}</p>
          <form className="mt-4" onSubmit={handleSubmit}>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="email"
              name="email"
              id="email"
              onChange={handleInputChange}
              placeholder="email"
            />
            <button
              className={`
            py-3
            px-8
            bg-primary-green
            hover:bg-primary-darkGreen
            rounded
            text-white
            font-bold
            mt-4
            `}
              type="submit"
            >
              sign up
            </button>
            <button
              className={`
            py-3
            px-8
            bg-primary-lighter
            hover:bg-primary-darker
            rounded
            text-white
            font-bold
            mt-4
            ml-4
            `}
              type="button"
              onClick={() => setEmailModalOpen(false)}
            >
              cancel
            </button>
          </form>
        </div>
      </ReactModal>
    </Layout>
  );
};
